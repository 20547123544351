/** 买入管理 */
<template>
  <div class="my-buy">
    <router-link style="float: right;" to="/my/trading/buy_form">
      <el-button class="mb-30" type="primary" size="small">发布需求</el-button>
    </router-link>
    <el-table :data="tableData" border :height="$isPc ? '630px' : '100%'" v-loading="loading">
      <el-table-column v-for="(item, index) in tableLabel" :key="index" :prop="item.value" :label="item.label"
        :width="item.width">
        <template slot-scope="scope">
          <template v-if="item.value === 'active'">
            <router-link :to="{ path: `/my/trading/buy_form`, query: { id: scope.row.id } }">
              <el-button type="primary" size="mini" class="mr-20">查看详情</el-button>
            </router-link>
            <el-popconfirm title="确定删除改商品吗？" @confirm="delAsktobuy(scope.$index, tableData, scope.row.id)">
              <el-button type="danger" size="mini" slot="reference">删除</el-button>
            </el-popconfirm>
          </template>

          <template v-else>
            {{ scope.row[item.value] }}
          </template>
        </template>
      </el-table-column>
    </el-table>
    <Page :total="pageTotal"  :pageSize="15" :resetPage="resetPage" @page="changePage" />
  </div>
</template>

<script>
import * as TradingApi from '@/api/trading'
import Page from '@/components/Page.vue'
export default {
  name: 'MyTradingBuy',
  components: {
    Page
  },
  data () {
    return {
      tableLabel: [
        { label: '求购知产', value: 'name_text' },
        { label: '求购类型', value: 'type' },
        { label: '预算 (元)', value: 'price_text' },
        { label: '求购状态', value: 'state_text' },
        { label: '求购时限', value: 'expired_at' },
        { label: '发布时间', value: 'release_at' },
        { label: '操作', value: 'active', width: '210px' }
      ],
      tableData: [],
      page: 1,
      pageTotal: 0,
      resetPage: false,//是否重置分页
      loading: false,
      finished: false, // 是否加载完成
    }
  },

  activated () {
    if (this.$store.getters.isTradingListReset) {
      this.page = 1
      this.resetPage = !this.resetPage
    }
    this.getAsktobuy()
    this.$store.dispatch('setIsTradingListReset', false)
  },
  methods: {
    async getAsktobuy () {
      this.loading = true
      try {
        let { data } = await TradingApi.getAsktobuy(this.page)
        data.data = data.data.map(v => {
          v['name_text'] = v['name'] === 'Patent' ? '专利' : '商标'
          v['state_text'] = v['state'] == 1 ? '未审核' : v['state'] == 2 ? '审核通过' : '审核未通过'
          v['price_text'] = v['price'] == 0 ? '面议' : v['price']
          return v
        })
        this.tableData = this.$isPc ? data.data : [...this.tableData, ...data.data]
        this.pageTotal = data.total
      } catch (error) { }
      this.loading = false
    },
    // 切换分页
    changePage (page) {
      this.page = page
      this.getAsktobuy()
    },
    // 删除订单
    async delAsktobuy (index, rows, id) {
      try {
        if (!this.$store.getters.userToken) return this.$message.warning('请先登录');
        let { code, msg } = await TradingApi.delAsktobuy(id)
        if (code == 200) {
          this.$message.success(msg);
          rows.splice(index, 1);
        } else {
          this.$message.error(msg);
        }
      } catch (error) { }
    }
  }
}
</script>
<style lang="scss" scoped></style>