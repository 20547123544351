// 交易平台
import request from "@/utils/request.js"

// 获取知产类别
export const getTradingtype = (type) => {
  return request({
    method: 'get',
    url: '/getclasstype',
    headers: {
      token: true
    },
    params: { type }
  })
}

// 商品下单
export const assetOrder = (data) => {
  return request({
    method: 'post',
    url: '/asset-order/placeorder',
    headers: {
      token: true
    },
    data
  })
}


/**
 * 交易平台 - 开放许可
 */
// 获取开放许可列表
export const getLicenseProductList = (data) => {
  return request({
    method: 'get',
    url: '/open-license/getgoodlist',
    params: data
  })
}
// 获取单个开放许可商品
export const fetchLicenseProduc = (id) => {
  return request({
    method: 'get',
    url: '/open-license/getgood',
    headers: {
      token: true
    },
    params: { id }
  })
}

/**
 * 交易平台 - 专利交易
 */
// 获取专利交易列表
export const getPatentProductList = (data) => {
  return request({
    method: 'get',
    url: '/patent-good/getgoodlist',
    params: data
  })
}
// 获取单个专利交易商品
export const fetchPatentProduc = (id) => {
  return request({
    method: 'get',
    url: '/patent-good/getgood',
    headers: {
      token: true
    },
    params: { id }
  })
}

/**
 * 交易平台 - 商标交易
 */
// 获取商标交易列表
export const getTrademarkProductList = (data) => {
  return request({
    method: 'get',
    url: '/trademark-good/getgoodlist',
    params: data
  })
}
// 获取单个商标交易商品
export const fetchTrademarkProduc = (id) => {
  return request({
    method: 'get',
    url: '/trademark-good/getgood',
    headers: {
      token: true
    },
    params: { id }
  })
}

/**
 * 交易平台 - 资产包
 */
// 获取专利交易列表
export const getPackProductList = (data) => {
  return request({
    method: 'get',
    url: '/assetpack-good/getgoodlist',
    params: data
  })
}
// 获取单个专利交易商品
export const fetchPackProduc = (id) => {
  return request({
    method: 'get',
    url: '/assetpack-good/getgood',
    headers: {
      token: true
    },
    params: { id }
  })
}

/**
 * 卖出管理 - 专利
 */
// 获取专利列表
export const getPatentList = (data) => {
  return request({
    method: 'get',
    url: '/patent/getpatentlist',
    headers: {
      token: true
    },
    params: data
  })
}

// 获取单个专利商品
export const fetchPatent = (id) => {
  return request({
    method: 'get',
    url: '/patent/getpatent',
    headers: {
      token: true
    },
    params: {
      id
    }
  })
}

// 查询专利信息
export const searchPatentInfo = (patent_ano) => {
  return request({
    method: 'get',
    url: '/patent/info',
    headers: {
      token: true
    },
    params: {
      patent_ano
    }
  })
}

// 发布专利商品
export const addPatent = (data) => {
  return request({
    method: 'post',
    url: '/patent/publishdata',
    headers: {
      token: true
    },
    data
  })
}

// 编辑专利商品
export const editPatent = (data) => {
  return request({
    method: 'post',
    url: '/patent/modifypatent',
    headers: {
      token: true
    },
    data
  })
}

// 删除专利商品
export const delPatent = (id) => {
  return request({
    method: 'post',
    url: '/patent/delpatent',
    headers: {
      token: true
    },
    params: {
      id
    }
  })
}

/**
 * 卖出管理 - 商标
 */
// 获取商标列表
export const getTrademarkList = (data) => {
  return request({
    method: 'get',
    url: '/trademark/gettrademarklist',
    headers: {
      token: true
    },
    params: data
  })
}

// 获单个商标商品
export const fetchTrademark = (id) => {
  return request({
    method: 'get',
    url: '/trademark/gettrademark',
    headers: {
      token: true
    },
    params: {
      id
    }
  })
}

// 查询商标信息
export const searchTrademarkInfo = (trademark_ano) => {
  return request({
    method: 'get',
    url: '/trademark/info',
    headers: {
      token: true
    },
    params: {
      trademark_ano
    }
  })
}

// 发布商标商品
export const addTrademark = (data) => {
  return request({
    method: 'post',
    url: '/trademark/publishdata',
    headers: {
      token: true
    },
    data
  })
}

// 编辑商标商品
export const editTrademark = (data) => {
  return request({
    method: 'post',
    url: '/trademark/modifytrademark',
    headers: {
      token: true
    },
    data
  })
}

// 删除商标商品
export const delTrademark = (id) => {
  return request({
    method: 'post',
    url: '/trademark/deltrademark',
    headers: {
      token: true
    },
    params: {
      id
    }
  })
}

/**
 * 卖出管理 - 资产包
 */
// 获取资产包列表
export const getPackList = (page) => {
  return request({
    method: 'get',
    url: '/pack/getpacklist',
    headers: {
      token: true
    },
    params: {
      page
    }
  })
}

// 获单个资产包
export const fetchPack = (id) => {
  return request({
    method: 'get',
    url: '/pack/getpack',
    headers: {
      token: true
    },
    params: {
      id
    }
  })
}

// 发布专利资产包
export const addPatentPack = (data) => {
  return request({
    method: 'post',
    url: '/pack/publishpatentpack',
    headers: {
      token: true
    },
    data
  })
}

// 发布商标资产包
export const addTrademarkPack = (data) => {
  return request({
    method: 'post',
    url: '/pack/publishtrademarkpack',
    headers: {
      token: true
    },
    data
  })
}

// 编辑资产包
export const editPack = (data) => {
  return request({
    method: 'post',
    url: '/pack/modifypack',
    headers: {
      token: true
    },
    data
  })
}

// 删除资产包
export const delPack = (id) => {
  return request({
    method: 'post',
    url: '/pack/delpack',
    headers: {
      token: true
    },
    params: {
      id
    }
  })
}

/** 
 * 买入管理
 */
// 获取求购列表
export const getAsktobuy = (page) => {
  return request({
    method: 'get',
    url: '/asktobuy/getbuylist',
    headers: {
      token: true
    },
    params: {
      page
    }
  })
}

// 获单个求购信息
export const fetchAsktobuy = (id) => {
  return request({
    method: 'get',
    url: '/asktobuy/getbuy',
    headers: {
      token: true
    },
    params: {
      id
    }
  })
}

// 新增求购商品
export const addAsktobuy = (data) => {
  return request({
    method: 'post',
    url: '/asktobuy/publishdata',
    headers: {
      token: true
    },
    data
  })
}

// 编辑求购商品
export const editAsktobuy = (data) => {
  return request({
    method: 'post',
    url: '/asktobuy/modifybuy',
    headers: {
      token: true
    },
    data
  })
}

// 删除求购商品
export const delAsktobuy = (id) => {
  return request({
    method: 'post',
    url: '/asktobuy/delbuy',
    headers: {
      token: true
    },
    params: {
      id
    }
  })
}